#battery-graph-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background: #171719;
    padding-block: 10px;
    // padding-inline: 10px;

    h1 {
        color: #2C2B29;
        margin: 0;
        font-size: 20px;
    }

    .total-data {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: baseline;
        margin-block: 10px;

        .total-data-stats {
            display: flex;
            justify-content: space-around;
            width: 100%;
            // div:nth-child(1) {
            //     margin-right: 26px;
            // }

            h3 {
                margin: 0;
                margin-bottom: 2px;
                font-size: 13px;
                color: #2C2B29;
            }

            h4 {
                margin: 0;
                font-size: 12px;
                color: #2C2B29;
            }
        }

        h2 {
            margin: 0;
            font-size: 18px;
            color: #2C2B29;
        }
    }

    .graph-container {
        position: relative;
    }

    .line-graph-container {
        position: relative;
    }
}


.data-item {
    display: flex;
    flex-direction: column;

    span:first-child {
        margin-bottom: 5px;
        margin-top: 2px;
        font-size: 16px;
        font-weight: bold;

    }

    span {
        font-size: 14px;
    }

    hr {
        width: 100%;
    }
}

.battery-stats {
    h3 {
        text-align: center;
    }
}

.trip-graph {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        margin-bottom: 5px !important;
    }
}

.soc-graph {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tooltip-v1 {
    background: #fff !important;
    max-width: 100px;
    color: #000 !important;
    border-radius: 10px !important;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.26);

    th {
        height: 12px;

        span {
            font-size: 12px;
            font-weight: normal;
        }

        h3 {
            font-weight: normal;
        }
    }

    tr {
        td {
            line-height: 8px;
            // padding-top: 3px;
            padding: 0px;
            min-width: 86px;
        }
    }

    span.reading-time-text {
        float: left;
        width: 100%;
        font-size: 8px;
    }

    span.dataset-label-text {
        float: left;
        width: 100%;
        font-size: 10px;
    }

    span.reading-date-text {
        font-size: 8px;
    }

    &.bar-chart {
        min-width: 90px !important;

        th {
            height: 8px;

            span {
                font-size: 7px;
                font-weight: normal;
                white-space: nowrap;
            }
        }

        tr {
            td {
                line-height: 6px;
            }
        }

        span.reading-date-text {
            font-size: 6px;
        }
    }
}