.camera-container {
    position: relative;
    transition: all 0.3s ease-in-out;
  }
  
  .camera-container.fullscreen {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;  
    video{
        width : 100%
    }
  }
//   #container-circles {
//     bottom: 0px;
// }
  
  .my-camera {
    width: 100%;
    height: 100%;
  }
  
  .photo-preview {
    text-align: center;
  }
  
  .photo-preview img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }
  
  .toggle-camera-btn {
       padding: 10px 13px;
       background-color: #f7f7f7;
       cursor: pointer;
       outline: none;
       border-radius: 10px;
  }
  
 
  
  .close-btn{
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  .switch-camera-btn{
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  #display-error{
    display: none;
  }