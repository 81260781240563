
.container_wrapper{
    height: 100vh;
}
/* Alert Window Styles */
.Alert-Window {
    font-size: 12px !important;
    position: fixed;
    bottom: -100%;
    left:50%;
    translate: -50% 0;
    border-radius: 25px;
    box-shadow: 3px 4px 10px gray !important;
    z-index: 9999999;
    padding: 0 1rem;
    min-width: 90%;
    min-height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: right 1s ease; /* Transition for sliding in */
    animation: fadeIn 0.5s ease-out; /* Optional fade-in effect */
}

/* Animation to slide in from right */
@keyframes slideIn {
    0% { bottom: -100%; }
    100% { bottom: 3%; }
}
@keyframes slideOut {
    0% { bottom: 3%; }
    100% { bottom: -100%; }
}

/* Trigger the slide-in animation when the alert is visible */
.Alert-Window.show {
    animation: slideIn 1s ease forwards;
}
.Alert-Window.close {
    animation: slideOut 1s ease reverse;
}

/* Alert Content */
.Alert-Window .alert-content {
    padding: 0.5rem;
}

/* Alert Action */
 .alert-action {
    cursor: pointer;
    text-align: center;
    width: 15px;
    padding: 0.3rem;
    margin-right: 5px;
    font-size: 15px;
    font-weight: 600;
    color: inherit;
}



/* Alert Types */
.primary { 
    color: #fff;
    background-color:  #007bff;
    border-left: 4px solid #007bff;
 }
.success { 
    color: #fff;
    background-color:  #23bca8;
    border-left: 4px solid #23bca8; 
}
.warning { 
    color: #fff;
    background-color:  #d39e00;
    border-left: 4px solid #d39e00;
 }
.danger { 
    color: #fff;
    background-color:  #f17170;
    border-left: 4px solid #f17170;
 }

/* Optional fade-in effect for alert window */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
