.no-data-grap-container {
    position: relative;

    .ovelay {

        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        span.no-data {
            margin-bottom: 30px;
        }

        // span.no-data {
        //     position: absolute;
        //     width: 100%;
        //     text-align: center;
        //     height: 100%;
        //     top: 10%;
        // }
    }
}

$spinner: #189a8f;
$background: white;
$size: 30px;

.spinner {
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 10px);
    width: $size;
    height: $size;
    animation: spinner 0.75s linear infinite;

    span {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .spinner-inner-1 {
        background: linear-gradient(to right, rgba($spinner, 0) 0%, rgba($spinner, 0) 50%, $spinner 51%);
    }

    .spinner-inner-2 {
        background: linear-gradient(to top, rgba($background, 0) 0%, $background 100%);
    }

    &.isVlectra {
        .spinner-inner-1 {
            background: linear-gradient(to right, rgba(#d5b300, 0) 0%, rgba(#d5b300, 0) 50%, #d5b300 51%);
        }
    }


    .spinner-inner-3 {
        $offset: $size * 0.1;
        top: $offset;
        left: $offset;
        width: $size - ($offset * 2);
        height: $size - ($offset * 2);
        background: $background;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}