$turquoise-blue:#d5b300;
$cloudy-blue: #d3d3d3;
$primary-font:"SFProText-Medium", sans-serif;

.filter-button{
    display: flex;
    margin-block: 5px;
    width: 80%;
    button{
        padding:0px 22.1px 13px 21.1px;
        background-color: transparent;
        border: 1px solid $cloudy-blue;
        border-left: 0;
        color: $cloudy-blue;
        font-size: 12px;
        font-family: $primary-font;
        width: 100%;
        line-height: 28px;
        height: 28px;
        display: flex;
        white-space: nowrap;
        text-align: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.4s ease-in-out;
        &#isVlektra{
            &:hover, &.active{
                border-color: $turquoise-blue;
                color: $turquoise-blue;
                border: 1px solid $turquoise-blue  !important;
            }
        }
        &:hover, &.active{
            border-color: #189a8f;
            color: #189a8f;
            border: 1px solid #189a8f  !important;
        }

    &:nth-child(1){
        border-left:  1px solid  $cloudy-blue;
        border-radius: 15px 0px 0px 15px;
    }
    &:nth-last-child(1){
       border-radius:0px 15px 15px 0px;
    //    border-left: none !important;
    }

    }
}